import { type FunctionComponent, useCallback, useMemo } from 'react';
import {
    useTranslate,
    type IResourceComponentsProps,
    useNavigation,
    useCan,
    useCustom,
    useApiUrl,
    type HttpError,
} from '@refinedev/core';
import { List, TextField, useTable, EditButton } from '@refinedev/antd';
import { DownloadOutlined } from '@ant-design/icons';
import { Table, Col, Row, Space } from 'antd';

import { REACT_QUERY_MICRO_CACHE_TTL, DEFAULT_NA_VALUE, DEFECT_CHART_MAX_ITEMS } from '@/constants';

import DownloadButton from '@/components/DownloadButton';
import TableSettingsButton from '@/components/TableSettingsButton';
import TableItemActionsMenu from '@/components/TableItemActionsMenu';
import InspectionResultBadge from '@/components/InspectionResultBadge';
import InspectionDefectsChart from '@/components/InspectionDefectsChart';
import InspectionsFilters from './InspectionsFilters';

import useTableSettings from '@/hooks/useTableSettings';
import onClickCapture from '@/utils/onClickCapture';
import downloadInspectionReport from '@/utils/inspection/downloadInspectionReport';
import downloadInspectionFlashFile from '@/utils/inspection/downloadInspectionFlashFile';
import getFactoryTitle from '@/utils/factory/getFactoryTitle';
import { errorNotification } from '@/notifications';
import onSearch, { type SearchParams } from './InspectionsFilters/onSearch';
import type { TableColumn } from '@/interfaces';
import type { IInspection, IInspectionDefectSummary } from '@/interfaces/inspections';
import type { IProject } from '@/interfaces/projects';
import type { IProductType } from '@/interfaces/productTypes';
import { DefectType } from '@/interfaces/enums/defects';
import { InspectionStatus } from '@/interfaces/enums/inspections';

type InspectionColumns = TableColumn<
    IInspection,
    keyof IInspection | ['project', 'name'] | ['productType', 'name'] | 'actions'
>[];

const tBase = 'inspections.list';

export const InspectionsList: FunctionComponent<IResourceComponentsProps> = () => {
    const t = useTranslate();
    const apiUrl = useApiUrl();
    const { show } = useNavigation();
    const { data: { can: canCreate } = {} } = useCan({ resource: 'inspections', action: 'create' });
    const { data: { can: canGetDefects } = {} } = useCan({ resource: 'defects', action: 'get' });

    const { tableProps, searchFormProps, filters } = useTable<IInspection, HttpError, SearchParams>(
        {
            onSearch,
            errorNotification,
            sorters: {
                initial: [{ field: 'createdAt', order: 'desc' }],
            },
        },
    );

    const inspections = tableProps.dataSource;

    const { data: selmaDefectsData, isLoading: isSelmaLoading } = useCustom<
        IInspectionDefectSummary[]
    >({
        url: `${apiUrl}/inspections/chart/selma`,
        method: 'get',
        queryOptions: {
            staleTime: REACT_QUERY_MICRO_CACHE_TTL,
            enabled: !!canGetDefects,
        },
        config: {
            filters: [
                ...filters,
                { field: 'status', operator: 'eq', value: InspectionStatus.COMPLETED },
            ],
        },
    });

    const { data: psiDefectsData, isLoading: isPsiLoading } = useCustom<IInspectionDefectSummary[]>(
        {
            url: `${apiUrl}/inspections/chart/psi`,
            method: 'get',
            queryOptions: {
                staleTime: REACT_QUERY_MICRO_CACHE_TTL,
                enabled: !!canGetDefects,
            },
            config: {
                filters: [
                    ...filters,
                    { field: 'status', operator: 'eq', value: InspectionStatus.COMPLETED },
                ],
            },
        },
    );

    const selmaDefectsSummary = selmaDefectsData?.data;
    const psiDefectsSummary = psiDefectsData?.data;

    const onDownloadFlashDataClick = useCallback(
        (id: IInspection['id']) => {
            const inspection = inspections?.find((item) => item.id === id);
            downloadInspectionFlashFile(inspection);
        },
        [inspections],
    );

    const columns: InspectionColumns = useMemo(
        () => [
            {
                name: 'name',
                render: (value?: IInspection['name']) => (
                    <TextField value={value || DEFAULT_NA_VALUE} />
                ),
                sorter: true,
            },
            {
                name: ['project', 'name'],
                render: (value?: IProject['name']) => (
                    <TextField value={value || DEFAULT_NA_VALUE} />
                ),
                sorter: true,
            },
            {
                name: ['productType', 'name'],
                render: (value?: IProductType['name']) => (
                    <TextField value={value?.value || DEFAULT_NA_VALUE} />
                ),
                sorter: true,
            },
            {
                name: 'factory',
                render: (value?: IInspection['factory']) => (
                    <TextField value={getFactoryTitle(value) || DEFAULT_NA_VALUE} />
                ),
            },
            {
                name: 'result',
                render: (value?: IInspection['result']) => <InspectionResultBadge result={value} />,
                sorter: true,
            },
            {
                name: 'actions',
                render: (_value: void, entity: IInspection) => (
                    <Space>
                        <DownloadButton
                            size='small'
                            disabled={!entity.isReportUploaded || !entity.reportDownloadingUrl}
                            title={t(`${tBase}.buttons.downloadReport`)}
                            onClick={(event) => {
                                event.stopPropagation();
                                downloadInspectionReport(entity);
                            }}
                        >
                            {t(`${tBase}.buttons.downloadReport`)}
                        </DownloadButton>

                        <EditButton
                            hideText={true}
                            size='small'
                            recordItemId={entity.id}
                            onClickCapture={onClickCapture}
                        />

                        <TableItemActionsMenu
                            tBase={tBase}
                            entityId={entity.id}
                            resource='inspections'
                            showDelete={true}
                            items={[
                                {
                                    key: 'downloadFlash',
                                    label: t(`${tBase}.buttons.downloadFlashData`),
                                    icon: <DownloadOutlined />,
                                    disabled:
                                        !entity.isFlashDataUploaded ||
                                        !entity.flashDataDownloadingUrl,
                                },
                            ]}
                            onMenuItemClick={(key, entityId) =>
                                key === 'downloadFlash' && onDownloadFlashDataClick(entityId)
                            }
                        />
                    </Space>
                ),
            },
        ],
        [onDownloadFlashDataClick, t],
    );

    const { columnsList, settings, onSettingsChange } = useTableSettings(
        columns,
        tBase,
        'inspections',
    );

    const itemsCount =
        selmaDefectsSummary?.length == null
            ? undefined
            : Math.min(DEFECT_CHART_MAX_ITEMS, selmaDefectsSummary?.length);

    return (
        <List title={t('inspections.titles.list')} canCreate={canCreate}>
            <Row gutter={[16, 16]}>
                <Col span={24}>
                    <Row gutter={[16, 16]}>
                        <Col span={6}>
                            <InspectionsFilters formProps={searchFormProps} />
                        </Col>
                        <Col span={18}>
                            <Row gutter={[16, 16]} style={{ marginBottom: 16 }}>
                                <Col xs={12}>
                                    <InspectionDefectsChart
                                        tBase={tBase}
                                        defectType={DefectType.SELMA}
                                        defectsSummary={selmaDefectsSummary}
                                        isLoading={isSelmaLoading}
                                        count={itemsCount}
                                    />
                                </Col>
                                <Col xs={12}>
                                    <InspectionDefectsChart
                                        tBase={tBase}
                                        defectType={DefectType.PSI}
                                        defectsSummary={psiDefectsSummary}
                                        isLoading={isPsiLoading}
                                        count={itemsCount}
                                    />
                                </Col>
                                <Col xs={24} className='text-right'>
                                    <TableSettingsButton
                                        title={t(`${tBase}.tableSettings.title`)}
                                        columnsList={columnsList}
                                        settings={settings}
                                        onSettingsChange={onSettingsChange}
                                    />
                                </Col>
                                <Col xs={24}>
                                    <Table
                                        {...tableProps}
                                        rowKey='id'
                                        rowClassName='cursor-pointer'
                                        className='table-with-header-cell-nowrap'
                                        onRow={(entity) => {
                                            return {
                                                onClick: () => show('inspections', entity.id),
                                            };
                                        }}
                                    >
                                        {columns.map(({ name, title, hidden, ...columnProps }) => {
                                            const key = Array.isArray(name) ? name.join('.') : name;
                                            return (
                                                <Table.Column
                                                    {...columnProps}
                                                    dataIndex={name}
                                                    key={key}
                                                    hidden={hidden ?? !settings[key]}
                                                    title={title ?? t(`${tBase}.fields.${key}`)}
                                                />
                                            );
                                        })}
                                    </Table>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </List>
    );
};
