import { ReactNode, useMemo, type FunctionComponent } from 'react';
import { Card, Table, Typography } from 'antd';
import { useCan, useTranslate } from '@refinedev/core';
import { DateField, TextField } from '@refinedev/antd';

import { DATE_FORMAT_DAY, DEFAULT_NA_VALUE } from '@/constants';

import DownloadButton from '@/components/DownloadButton';

import downloadAuditReport from '@/utils/audit/downloadAuditReport';
import downloadNccaAuditReport from '@/utils/audit/downloadNccaAuditReport';
import formatFactoryLocation from '@/utils/factory/formatFactoryLocation';
import type { TableColumn } from '@/interfaces';
import type { IAudit } from '@/interfaces/audits';

import c from './AuditBaseCard.module.css';

export type Props = {
    tBase: string;
    audit?: IAudit;
};

type SummaryItem = { name: string; value?: string | number | ReactNode };

type SummaryColumns = TableColumn<SummaryItem, keyof SummaryItem>[];

const AuditBaseCard: FunctionComponent<Props> = ({ tBase, audit }) => {
    const t = useTranslate();
    const { data: { can: canGetCompanies } = {} } = useCan({
        resource: 'companies',
        action: 'get',
    });

    const items = useMemo((): SummaryItem[] => {
        const newItems: SummaryItem[] = [
            { name: t(`${tBase}.fields.name`), value: audit?.name },
            {
                name: t(`${tBase}.fields.type`),
                value: audit?.type?.value
                    ? t(`audits.auditType.${audit.type.value}`, audit.type.value)
                    : '',
            },
        ];

        if (canGetCompanies) {
            newItems.push({ name: t(`${tBase}.fields.client.name`), value: audit?.client?.name });
        }

        newItems.push(
          { name: t(`${tBase}.fields.project.name`), value: audit?.project?.name },
          { name: t(`${tBase}.fields.factory.brand`), value: audit?.factory?.brand?.name },
            {
                name: t(`${tBase}.fields.factory.location`),
                value: formatFactoryLocation(audit?.factory),
            },
            {
                name: t(`${tBase}.fields.productType.name`),
                value: audit?.productType?.name.value,
            },
            {
                name: t(`${tBase}.fields.startDate`),
                value: audit?.startDate ? (
                    <DateField value={audit.startDate} format={DATE_FORMAT_DAY} />
                ) : (
                    ''
                ),
            },
            {
                name: t(`${tBase}.fields.completeDate`),
                value: audit?.completeDate ? (
                    <DateField value={audit.completeDate} format={DATE_FORMAT_DAY} />
                ) : (
                    ''
                ),
            },
            {
                name: t(`${tBase}.fields.reportDate`),
                value: audit?.reportDate ? (
                    <DateField value={audit.reportDate} format={DATE_FORMAT_DAY} />
                ) : (
                    ''
                ),
            },
            {
                name: t(`${tBase}.fields.nccaCompleteDate`),
                value: audit?.reportDate ? (
                    <DateField value={audit.nccaCompleteDate} format={DATE_FORMAT_DAY} />
                ) : (
                    ''
                ),
            },
            // { name: t(`${tBase}.fields.auditFiles`), value: audit?.auditFiles },
            {
                name: t(`${tBase}.fields.nccaReportDownloadingUrl`),
                value: (
                    <DownloadButton
                        size='small'
                        hideText={true}
                        title={t(`${tBase}.buttons.downloadNccaAuditReport`)}
                        disabled={!audit?.isNccaReportUploaded || !audit?.nccaReportDownloadingUrl}
                        onClick={() => downloadNccaAuditReport(audit)}
                    />
                ),
            },
            {
                name: t(`${tBase}.fields.reportDownloadingUrl`),
                value: (
                    <DownloadButton
                        size='small'
                        hideText={true}
                        title={t(`${tBase}.buttons.downloadAuditReport`)}
                        disabled={!audit?.isReportUploaded || !audit?.reportDownloadingUrl}
                        onClick={() => downloadAuditReport(audit)}
                    />
                ),
            },
        );

        return newItems;
    }, [audit, canGetCompanies, t, tBase]);

    const columns: SummaryColumns = useMemo(
        () => [
            {
                name: 'name',
                render: (value: SummaryItem['name']) => (
                    <Typography.Title level={5} className='m-0'>
                        <TextField value={value} />
                    </Typography.Title>
                ),
            },
            {
                name: 'value',
                align: 'right',
                render: (value?: SummaryItem['value']) => {
                    if (value == null) {
                        return <TextField value={DEFAULT_NA_VALUE} />;
                    }

                    if (typeof value === 'string') {
                        return <TextField value={value} />;
                    }

                    return value;
                },
            },
        ],
        [],
    );

    return (
        <Card className={c.card}>
            <Table
                dataSource={items}
                bordered={false}
                pagination={false}
                showHeader={false}
                rowKey='name'
                className={c.list}
            >
                {columns.map(({ name, title, ...columnProps }) => {
                    const key = Array.isArray(name) ? name.join('.') : name;
                    return <Table.Column {...columnProps} dataIndex={name} key={key} />;
                })}
            </Table>
        </Card>
    );
};

export default AuditBaseCard;
